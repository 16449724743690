<template>
  <div id="team-members-modifiy">
    <v-card>
      <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
        <v-card-title class="pa-0">
          <!-- <span class="headline"></span> -->
          <v-toolbar flat>
            <v-toolbar-title
              >{{ pageDescription }} - {{ YearName }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon @click="closePrompt">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>

        <v-card-text style="height: 70%">
          <v-container class="py-0">
            <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
              <b-spinner
                variant="primary"
                type="grow"
                label="Loading..."
              ></b-spinner>
            </div>
            <v-container v-if="StartupLoadingFlag">
              <v-row wrap>
                <v-col cols="12" md="4" v-for="n in 6" :key="n">
                  <v-skeleton-loader
                    v-bind="attrs"
                    type="paragraph"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-container>
            <v-row wrap v-if="ProgessStart == 1">
              <v-col align="center" cols="12" md="12">
                <v-progress-circular
                  :rotate="180"
                  :size="100"
                  :width="15"
                  :value="uploadPercentage"
                  color="#00a4ef"
                >
                  {{ uploadPercentage }}
                </v-progress-circular>
                <h3 class="red--text">
                  Please wait, we are uploading your details. Time may vary
                  based on your internet connection.
                </h3>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="!StartupLoadingFlag">
            <v-row wrap>
              <v-col cols="12" md="6">
                <label>*Designation</label>
                <h3>{{ DesignationName }}</h3>
                <!-- <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="DesignationIdOptionsLoading"
                  :items="DesignationIdOptions"
                  :rules="DesignationIdRules"
                  v-model="DesignationId"
                  required
                  outlined
                  dense
                  clearable
                /> -->
              </v-col>
              <v-col cols="12" md="6">
                <label>*Member</label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="MemberIdOptionsLoading"
                  :items="MemberIdOptions"
                  :rules="MemberIdRules"
                  v-model="MemberId"
                  required
                  outlined
                  dense
                  clearable
                >
                  <!-- :disabled="index == 0 ? true : false" -->
                  <template v-slot:selection="{ item }">
                    <img width="50" height="50" :src="item.photo" />{{
                      item.text
                    }}
                  </template>
                  <template v-slot:item="{ item }">
                    <img width="50" height="50" :src="item.photo" />{{
                      item.text
                    }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closePrompt"
            elevation="30"
            shaped
            tile
            small
            color="#a52a2a"
            class="font-size-h6 px-10 mr-3 white--text"
          >
            Close
          </v-btn>
          <v-btn
            :disabled="!valid1"
            @click.prevent="confirmAlert"
            :loading="SubmitFlag"
            elevation="30"
            shaped
            tile
            small
            color="#1db954"
            class="font-size-h6 px-10 mr-3 white--text"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    editRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      valid1: true,

      search: "",
      selected: [],
      tableSelectFlag1: false,
      SubmitFlag: false,

      rows: [],
      tableColumns1: [],
      records: [],

      alert: {},

      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      ResultFlag: false,
      LoadingFlag: false,
      StartupLoadingFlag: false,

      AllMembers: [],

      LomCode: "",

      YearCodeRules: [(v) => !!v || "Year is required"],
      YearCode: "",
      YearCodeOptions: [],
      YearCodeOptionsLoading: false,

      TeamCodeRules: [(v) => !!v || "Team is required"],
      TeamCode: "",
      TeamCodeOptions: [],
      TeamCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      MemberIdRules: [(v) => !!v || "Member is required"],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      DesignationIdRules: [(v) => !!v || "Designation is required"],
      DesignationId: "",
      DesignationIdOptions: [],
      DesignationIdOptionsLoading: false,

      DesignationName: "",

      MemberNameRules: [(v) => !!v || "Member Name is required"],
      MemberName: "",

      EmailIdRules: [],
      EmailId: "",

      MobileNoRules: [],
      MobileNo: "",

      Address: "",

      CityNameRules: [],
      CityName: "",

      uploadedImages: [],

      FacebookRules: [],
      Facebook: "",

      TwitterRules: [],
      Twitter: "",

      InstagramRules: [],
      Instagram: "",

      MemberTypeId: "",

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.pageData();
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    rows: function () {
      console.log("watch rows");
      var n1 = this.rows.length;
      var flag = n1 == 0 ? 0 : 1;
      console.log("n1=" + n1 + ", flag=" + flag);
      this.records = this.rows[0];
    },
    YearCodeOptions: function () {
      this.YearCodeOptionsLoading = false;
    },
    TeamCodeOptions: function () {
      this.TeamCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      this.ZoneCodeOptionsLoading = false;
    },
    MemberIdOptions: function () {
      this.MemberIdOptionsLoading = false;
    },
    DesignationIdOptions: function () {
      this.DesignationIdOptionsLoading = false;
    },
    MemberId: function () {
      console.log("watch MemberId");
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log({ member });
      }
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "lgb",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      var recordData = this.recordData;
      console.log("recordData=" + JSON.stringify(recordData));
      var YearwiseOfficesHeldId = recordData.YearwiseOfficesHeldId;
      if (YearwiseOfficesHeldId != "") {
        this.getDesignationIdOptions();
        this.MemberId = recordData.MemberId;
        this.DesignationName = recordData.DesignationName;
        this.ZoneCode = recordData.ZoneId;
        this.LomCode = recordData.LomId;
        var DesignationId = recordData.DesignationId;
        var MemberTypeId = "";
        switch (DesignationId) {
          case 14:
            // Junior Jaycee Wing
            MemberTypeId = 9;
            break;

          case 15:
            // Jayceert Wing
            MemberTypeId = 8;
            break;

          default:
            MemberTypeId = 1;
            break;
        }
        this.DesignationId = DesignationId;
        this.MemberTypeId = MemberTypeId;
        this.getMemberIdOptions();
      } else {
        this.sweetAlert("error", "Designation details not fount", false);
        this.closePrompt();
      }
    },
    validate() {
      console.log("validate called");
      this.$refs.form1.validate();
    },
    reset() {
      console.log("reset called");
      this.$refs.form1.reset();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideEditRecordPrompt");
    },
    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
    },
    onFileSelected(event, k) {
      console.log("onFileSelected called");
      var n1 = this.uploadedImages.length;
      var file = event.target.files[0];
      console.log("k=" + k + ", event=" + JSON.stringify(file));
      this.uploadedImages[k] = file;
    },
    addNewRow(tableId) {
      console.log("addNewRow called");
      console.log("tableId=" + tableId);

      if (tableId == 2) {
        var n1 = this.AllMembers.length;
        console.log("n1=" + n1);
        // if (n1 < 30) {
        this.AllMembers.push({
          YearwiseOfficesHeldId: null,
          MemberId: 0,
          MemberName: "",
          DesignationId: 0,
          Designation: "",
        });
        // }
      }
    },
    deleteRow(tableId, index, tr) {
      console.log("deleteRow called");
      console.log(
        "tableId=" + tableId + ", index=" + index + ", tr=" + JSON.stringify(tr)
      );
      if (index > -1) {
        if (tableId == 2) {
          this.AllMembers.splice(index, 1);
        }
      }
    },
    getDesignationIdOptions() {
      console.log("getDesignationIdOptions called");
      this.DesignationIdOptionsLoading = true;
      var selectbox1_source = "DesignationId";
      var selectbox1_destination = "DesignationIdOptions";
      var selectbox1_url = "api/designation/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        OrganizationTypeId: 3,
        ExceptDesignationId: [1, 4, 5, 6, 7],
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMemberIdOptions() {
      console.log("getMemberIdOptions called");
      this.MemberIdOptionsLoading = true;
      var selectbox1_source = "MemberId";
      var selectbox1_destination = "MemberIdOptions";
      var selectbox1_url = "api/members/options";
      var selectbox1_conditions_array = {
        UserInterface: 2,
        LomCode: this.LomCode,
        MemberType: this.MemberTypeId,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        this.toast("error", "Kindly fill the required fields", true);
      }
    },
    submitForm() {
      // add todo in store and clear all fields in dialog
      console.log("submitForm called");

      // this.resetMessageTxt();

      var result = this.$refs.form1.validate();
      console.log("result=" + result);

      var LomCode = this.LomCode;
      var MemberId = this.MemberId;
      // var n1 = AllMembers.length;

      if (result) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/member-update";
        var upload = {
          UserInterface: 1,
          OrganizationTypeId: 3,
          YearCode: this.JciYearCode,
          ZoneCode: this.ZoneCode,
          LomCode: this.LomCode,
          MemberId: this.MemberId,
          DesignationId: this.DesignationId,
          YearwiseOfficesHeldId: this.recordData.YearwiseOfficesHeldId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.LoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.pageLoadingOn()

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            // thisIns.ProgessStart = 0;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              // thisIns.SuccessMessageTxtFlag = 1;
              // thisIns.SuccessMessageTxt = output;

              thisIns.closePrompt();
              // thisIns.AllMembers = [];
              // thisIns.ResultFlag = false;
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "";
        if (LomCode == "") {
          message += "LOM should not be empty. ";
        }
        if (MemberId == "") {
          message += "Member should not be empty. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    console.log("beforeMount called");
    var LomCode = this.$session.get("LomId");
    LomCode = LomCode == (null || undefined) ? "" : LomCode;
    console.log("LomCode=" + LomCode);

    var ZoneCode = this.$session.get("ZoneId");
    ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
    console.log("ZoneCode=" + ZoneCode);

    this.LomCode = LomCode;
    this.ZoneCode = ZoneCode;
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#team-members-modifiy {
  .v-dialog > .v-card > .v-toolbar {
    position: sticky;
    top: 0;
    z-index: 999;
  }
}
</style>